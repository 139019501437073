import React from 'react';
import { Link } from 'gatsby';
import styled, { keyframes } from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ContainerLeft from '../components/common/container-left';
import ContainerRight from '../components/common/container-right';
import logoSrc from '../assets/logo2x.png';
import bgSrc from '../assets/bg-404.png';

const Content = styled.div`
  @media(min-width: 960px) {
    display: flex;
    min-height: 100vh;
  }
`;

const StyledLink = styled(Link)`
  display: block;
  max-width: 201px;

  > img {
    display: block;
    height: auto;
    width: 100%;
  }
`;

const Headline = styled.h2`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 40px;
  margin: 56px 0 0 0;

  > span {
    color: ${({ theme }) => theme.colors.primaryLight};
  }

  @media(min-width: 960px) {
    margin: 90px 0 0 0;
  }
`;

const Text = styled.p`
  margin: 56px 0;
  max-width: 550px;

  > a {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const StyledImage = styled.img`
  animation: ${fadeIn} 250ms ease-out 500ms forwards;
  height: fill-available;
  object-fit: cover;
  opacity: 0;
  position: relative;
  width: 100%;
`;

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Page Not found" />
    <Content>
      <ContainerLeft>
        <StyledLink to="/">
          <img src={logoSrc} alt="GMDN Enroll" />
        </StyledLink>
        <Headline>
          Uh oh!
          <span> 404 Error</span>
        </Headline>
        <Text>
          We can’t seem to find the page you’re looking for.
          Please check your URL or head back to our
          {' '}
          <Link to="/">homepage</Link>
          .
        </Text>
      </ContainerLeft>

      <ContainerRight fullWidth desktop>
        <StyledImage src={bgSrc} alt="404: Page not found!" />
      </ContainerRight>
    </Content>
  </Layout>
);

export default NotFoundPage;
